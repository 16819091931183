import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import { Medailon, PaddingWrapper, H2 } from "@igloonet-web/shared-ui"
import Logo from "../../../images/reference/moravske-drevostavby/logo.png"
import ReferenceImageText from "../../../components/reference-detail/reference-image-text"
import Top from "../../../images/reference/moravske-drevostavby/top.jpg"
import Tajbr from "../../../images/reference/moravske-drevostavby/reaction-devel.png"
import ReferenceHeader from "../../../components/reference-detail/reference-header"

class Moravske extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>
            Reference na marketingové práce pro Moravské dřevostavby | igloonet
          </title>
          <meta
            name="description"
            content="Marketing, vývoj a hosting pod střechou jedné firmy. Programujeme moderní eshopy, poskytujeme prvotřídní hostingové služby a tvoříme marketingové strategie na míru."
          />
        </Helmet>
        <ReferenceHeader
          companyName="moravské dřevostavby"
          heading="Reference na marketingové práce pro Moravské dřevostavby"
          date="léto 2014 - současnost"
        />

        <ReferenceImageText
          halfImgSize
          right
          img={Top}
          eager
          alt=""
          logo={Logo}
          logoAlt="logo společnosti Moravské Dřevostavby"
          link="//moravske-drevostavby.cz/"
        >
          <p>
            Společnost MORAVSKÉ DŘEVOSTAVBY se zabývá výstavbou energeticky
            úsporných a nízkoenergetických typových dřevostaveb za použití
            Systému DNK. Ve své práci si berou za vzor moravskou poctivost a
            pohostinnost.
          </p>
          <PaddingWrapper>
            <h2>Zadání</h2>
            <p>
              Zvýšit návštěvnost na webu, počet odeslaných poptávek a dotazů na
              stavbu prostřednictvím placené reklamy ve vyhledávání. Vytvořit
              remarketingové kampaně v systému Google AdWords a Seznam Sklik.
            </p>
          </PaddingWrapper>
        </ReferenceImageText>

        <ReferenceImageText>
          <PaddingWrapper>
            <H2>Rozsah projektu</H2>
            <p className="text-center">správa a optimalizace PPC kampaní</p>
          </PaddingWrapper>
          <PaddingWrapper>
            <H2>Historie spolupráce</H2>
            <p>
              Spolupráce se společností MORAVSKÉ DŘEVOSTAVBY začala v roce 2014,
              kdy jsme vytvořili nový web na míru. Nyní se staráme o jeho další
              vývoj. V roce 2014 jsme také začali s prvními marketingovými
              aktivitami a nastavili jsme nové PPC kampaně ve vyhledávání.
              Klient byl s výsledky práce spokojen, a tak jsme rozšířili
              spolupráci na remarketingové kampaně.
            </p>
          </PaddingWrapper>
        </ReferenceImageText>

        <Medailon
          personName="Ing. Aleš Tajbr, Ph.D."
          position="ředitel"
          img={Tajbr}
        >
          <p>
            Díky kampaním ve vyhledávání a remarketingovým kampaním se nám
            podařilo získat nové zakázky.
          </p>
        </Medailon>
        <ContactForm contact="adam" />
      </Layout>
    )
  }
}

export default Moravske
